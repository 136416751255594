import { Streamfield, TaggedArticlesList } from '@/types';
import { getRequest } from '@api/wagtail';
import Subheader from '@components/Subheader';
import Streamfields from '@streamfields/Streamfields';
import { GetStaticPaths, GetStaticProps } from 'next';
import { BasePageProps, basePageWrap } from '../BasePage';

type Props = {
  content: Streamfield[];
} & BasePageProps;

function HomePage(props: Props) {
  const { content } = props;

  return (
    <div className='home-page'>
      <Subheader pagetype='homepage' />
      {content ? <Streamfields fields={content} /> : null}
    </div>
  );
}

export default basePageWrap<Props>(HomePage);

export const getStaticProps: GetStaticProps = async () => {
  const { json } = await getRequest<TaggedArticlesList>('pages', {
    type: 'contentHub.ArticlePage',
    fields: 'tags',
  });

  const tags: string[] = [];

  json.items.forEach(item => {
    item.tags.forEach(tag => {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    });
  });

  json.tags = tags;

  return { props: json };
};

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
});
