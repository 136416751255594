import SearchForm from '@components/SearchForm';
import Tags from 'components/Tags';

function FakeSearch() {
  return (
    <div className='search container'>
      <div className='search__wrapper'>
        <SearchForm />
        <Tags />
      </div>
    </div>
  );
}
export default FakeSearch;
