import { RawTag } from '@/types';
import { WagtailApiResponseError } from '@api/wagtail';

async function fetchAllTags(): Promise<RawTag[]> {
  const API_URL = process.env.WAGTAIL_API_URL ?? process.env.NEXT_PUBLIC_API_URL;
  const API_VERSION = process.env.WAGTAIL_API_VERSION ?? process.env.NEXT_PUBLIC_API_VERSION;
  const url = `${API_URL}/${API_VERSION}/tags`;

  const res = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(res, url, {});
    error.response = res;
    throw error;
  }

  const allTags = await res.json();

  return allTags;
}

export async function fetchImageSrcByID(id: number): Promise<string> {
  const API_URL = process.env.WAGTAIL_API_URL ?? process.env.NEXT_PUBLIC_API_URL;
  const API_VERSION = process.env.WAGTAIL_API_VERSION ?? process.env.NEXT_PUBLIC_API_VERSION;
  const url = `${API_URL}/${API_VERSION}/images/${id}`;

  const res = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(res, url, {});
    error.response = res;
    throw error;
  }

  const json = await res.json();
  const imageURL = json.meta.download_url;

  return imageURL;
}

export default fetchAllTags;
