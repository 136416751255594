import FakeSearch from '@components/FakeSearch';

type Props = { pagetype: string };

function Subheader(props: Props) {
  const { pagetype } = props;

  return (
    <div className='subheader'>
      <div className='container title_container'>
        {pagetype === 'homepage' ? (
          <h1 className='page-title'>Werkbank Labs</h1>
        ) : (
          <p className='page-title'>Werkbank Labs</p>
        )}
      </div>
      <FakeSearch />
    </div>
  );
}

export default Subheader;
