import { CleanedTag, RawTag } from '@/types';
import fetchAllTags from '@/utils/fetch';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { removeBaseUrl } from 'utils/url';
import ArrowDown from '../../styles/assets/images/keyboard_double_arrow_down.svg';
import ArrowUp from '../../styles/assets/images/keyboard_double_arrow_up.svg';

const requestTags = async () => {
  // Alle Tags von der API anfragen
  const tagList: RawTag[] = await fetchAllTags();

  // Meta-Daten der Tags verwerfen und Dubletten löschen
  const tags: CleanedTag[] = [];
  const comparisonList: number[] = [];

  tagList.forEach(rawtag => {
    if (!comparisonList.includes(rawtag.tag.id)) {
      tags.push(rawtag.tag);
      comparisonList.push(rawtag.tag.id);
    }
  });
  return tags;
};

function Tags() {
  const [tags, setTags] = useState<CleanedTag[]>();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await requestTags();
      setTags(response);
    })();
  }, []);

  return (
    <div className='tags-container'>
      <div className='tags wrapper'>
        {!showMore
          ? tags?.slice(0, 6).map(tag => (
              <Link
                key={tags.indexOf(tag)}
                href={removeBaseUrl(`/artikel/tag/${encodeURIComponent(tag.slug)}`)}
                className='tag'
              >
                {tag.name}
              </Link>
            ))
          : tags?.map(tag => (
              <Link
                key={tags.indexOf(tag)}
                href={removeBaseUrl(`/artikel/tag/${encodeURIComponent(tag.slug)}`)}
                className='tag'
              >
                {tag.name}
              </Link>
            ))}
      </div>
      <div className='button-container'>
        {showMore ? (
          <button className='button showLess' onClick={() => setShowMore(!showMore)}>
            <span className='icon'>
              <ArrowUp className='showLess' />
            </span>
            <span>Weniger anzeigen</span>
          </button>
        ) : (
          <button className='button showMore' onClick={() => setShowMore(!showMore)}>
            <span>Mehr anzeigen</span>
            <span className='icon'>
              <ArrowDown className='showMore' />
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default Tags;
