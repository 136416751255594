import { PageData, PageMetaDataList } from '@/types';
import { RequestInit } from 'next/dist/server/web/spec-extension/request';
import { ParsedUrlQuery } from 'querystring';

const API_URL = process.env.WAGTAIL_API_URL ?? process.env.NEXT_PUBLIC_API_URL;
const API_VERSION = process.env.WAGTAIL_API_VERSION ?? process.env.NEXT_PUBLIC_API_VERSION;
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export class WagtailApiResponseError extends Error {
  response: Response;

  constructor(res: Response, url: string, params: any) {
    super(`${res.status} - ${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`);
    this.name = 'WagtailApiResponseError';
    this.response = res;
  }
}

type Params = Record<string, string | readonly string[]>;

export async function getRequest<T>(
  url: string,
  params: ParsedUrlQuery = {},
  options: Partial<RequestInit> = {},
) {
  let headers = options?.headers ?? {};
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  };
  const queryString = new URLSearchParams(params as any);

  const res = await fetch(`${API_URL}/${API_VERSION}/${url}?${queryString.toString()}`, {
    ...options,
    headers,
  });

  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(res, url, params);
    throw error;
  }

  const json: T = await res.json();

  return {
    headers: res.headers,
    json,
  };
}

export async function getPage(
  path: string,
  params: Params = {},
  options: Partial<RequestInit> = {},
) {
  const page = await getRequest<PageData>(
    'pages/find',
    { ...params, html_path: path ?? '/' },
    options,
  );
  return page;
}

export async function getMenu() {
  const menu = await getRequest<PageMetaDataList[]>(`wagtailmenus_api/v1/main_menu/`, {
    current_url: BASE_URL,
  });

  return menu;
}

export async function getAllPages() {
  const pages = await getRequest<PageMetaDataList>('pages');
  return pages;
}

export async function getPagePreview(
  content_type: string,
  token: string,
  params: any = {},
  options: any = {},
) {
  // get id out of token
  const id = token.replace(/^id=([\d]+).*/gm, '$1');
  const res = await getRequest<PageData>(
    `page_preview/${id}/`,
    { ...params, content_type, token },
    options,
  );
  return res;
}

export async function getFooter() {
  try {
    return await getRequest('footer/find/?by_site=true');
  } catch (error) {
    return { json: null };
  }
}

export async function getHeader() {
  try {
    return await getRequest('header/find/?by_site=true');
  } catch (error) {
    return { json: null };
  }
}

export async function getFlatMenu(handle: string) {
  const res = await getRequest<PageMetaDataList[]>(
    `wagtailmenus_api/v1/flat_menu/?current_url=${BASE_URL}&handle=${handle}`,
  );

  return res;
}

export async function searchSite(query: ParsedUrlQuery) {
  const result = await getRequest<PageMetaDataList>('pages', query);
  return result;
}

export async function submitForm(data: any) {
  const submit = await getRequest('forms', undefined, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return submit;
}
