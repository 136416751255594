import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';

type Props = {
  inputValue?: string;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  className?: string;
  placeholder?: string;
};

function SearchForm(props: Props) {
  const { inputValue, onInputChange, className, placeholder } = props;

  return (
    <form method='GET' role='search' action='/suche/' className={clsx('search-form', className)}>
      <input
        type='text'
        name='autocomplete'
        className='search-form__input'
        value={inputValue}
        onChange={onInputChange}
        placeholder={placeholder}
        aria-label='Suchwort eingeben'
      />

      <button className='search-form__submit' type='submit' aria-label='Suche abschicken'>
        <MagnifyingGlassIcon color='#0c2f6e' width={64} height={48} />
      </button>
    </form>
  );
}

export default SearchForm;
